import React, { FC, useState } from 'react'
import styled from 'styled-components'

type PromptProps = {
  isWaiting?: boolean
  onInput?: (input: string) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 500px;
`

const Input = styled.input`
  flex: 1;
`

const Button = styled.button``

export const Prompt: FC<PromptProps> = ({ isWaiting, onInput }) => {
  const [text, setText] = useState('')

  const handleSubmit = () => {
    if (text.length === 0) return

    onInput?.(text)
  }

  return (
    <Container>
      <Input
        type="text"
        value={text}
        onChange={(e) => {
          setText(e.target.value)
        }}
        placeholder="例: 最も品のある焼き物を表示してください"
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.nativeEvent.isComposing) {
            handleSubmit()
          }
        }}
      />
      <Button onClick={handleSubmit} disabled={isWaiting}>
        AI
        {isWaiting && '...'}
      </Button>
    </Container>
  )
}
