/**
 * [begin, end) の数値列を生成する.
 *
 * @param begin 開始数
 * @param end 終了数
 * @returns 連番の数値の配列
 */
export const iota = (begin: number, end: number): number[] =>
  [...Array(end - begin)].map((_, i) => begin + i)

/**
 * 数値比較（浮動小数点数誤差を考慮）.
 *
 * @param a 比較対象1.
 * @param b 比較対象2.
 * @param epsilon 誤差の許容範囲（デフォルトで Number.EPSILON）
 * @returns a と b が（ほぼ）等しければ true.
 */
export const nearlyEqual = (
  a: number,
  b: number,
  epsilon: number = Number.EPSILON,
) => {
  return Math.abs(a - b) < epsilon
}

/**
 * ファンシーインデックス.
 *
 * @param array 任意の型の配列.
 * @param indices インデックスの配列.
 */
export const fancyIndexing = <T extends unknown[]>(
  array: T,
  indices: number[],
): T => {
  return indices.map((index) => array[index]!) as T
}

export const fullArray = <T>(size: number, fillValue: T): T[] =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Array.from({ length: size }, (_) => fillValue)
