import { useEffect, useState } from 'react'
import { Results } from '../../calculator/resources/types/result'
import { fetchLearningResult } from '../../calculator/resources/result'
import { fetchLabels } from '../../calculator/resources/label'
import { Labels } from '../../calculator/resources/types/labels'

export const useResults = (): {
  results: Results | undefined
  progress: number
} => {
  const [results, setData] = useState<Results>()
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      setData(await fetchLearningResult(setProgress))
    })()
  }, [])

  return { results, progress }
}

export const useLabels = (): {
  labels: Labels | undefined
  progress: number
} => {
  const [labels, setLabels] = useState<Labels>()
  const [progress, setProgress] = useState<number>(0)

  useEffect(() => {
    ;(async () => {
      setLabels(await fetchLabels(setProgress))
    })()
  }, [])

  return { labels, progress }
}
