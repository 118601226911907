import { nearlyEqual } from '../../utils/misc'

export const normalize = (val: number, min: number, max: number) =>
  nearlyEqual(min, max) ? 0 : (val - min) / (max - min)

const saturation = 90

function cut(val: number): number {
  if (val < 0) return 0
  if (val > 1) return 1
  return val
}

/** Map [0, 1] value to color: blue -> white -> red */
export function bwr(val: number): string {
  val = cut(val)

  if (val < 0.5) {
    const lightness = 50 + val * 2 * 50
    return `hsl(240 ${saturation}% ${lightness.toFixed(1)}%)`
  } else {
    const lightness = 50 + (1 - val) * 100
    return `hsl(0 ${saturation}% ${lightness.toFixed(1)}%)`
  }
}

/** Map [0, 1] value to color: white -> green */
export function wg(val: number): string {
  val = cut(val)

  const lightness = 50 + (1 - val) * 50
  return `hsl(120 ${saturation}% ${lightness.toFixed(1)}%)`
}

/** Map [0, 1] value to color: white -> orange */
export function wo(val: number): string {
  val = cut(val)

  const lightness = 50 + (1 - val) * 50
  return `hsl(30 ${saturation}% ${lightness.toFixed(1)}%)`
}

/** Map [0, 1] value to color: white -> pink */
export function wp(val: number): string {
  val = cut(val)

  const lightness = 50 + (1 - val) * 50
  return `hsl(340 ${saturation}% ${lightness.toFixed(1)}%)`
}

/** Map [0, 1] value to color: black -> white */
export function mono(val: number): string {
  val = cut(val)

  const lightness = (1 - val) * 100
  return `hsl(0 0% ${lightness.toFixed(1)}%)`
}
