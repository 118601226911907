import React, { FC, Fragment, useMemo, useRef } from 'react'
import { rng } from '../utils/random'

type Props = {
  data?: number[]
  coloring?: (v: number) => string
  resolution: number
  selectedUnit?: number | undefined
  onChangeSelectedUnit?: (v: number | undefined) => void
  size?: number

  /**
   * ユニットに対応するラベル
   *
   * 要素の型によって次のように振る舞う
   * - undefined: ラベルなし
   * - string: ラベルを1つだけ表示
   * - string[]: ラベルを複数表示
   *
   * また、要素が空文字または空配列の場合はラベルなしでユニットの点だけを表示する
   */
  labels?: (string | string[] | undefined)[]

  padding?: number

  /** 各ユニットのセルにN個の点をランダムに打つ */
  showDots?: number[]
}

type PrivateState = {
  isPointerDown: boolean
  unitOnPointerDown: number | undefined
  isMoved: boolean
  previouslySelectedUnit: number | undefined
}

export const SOMMap: FC<Props> = ({
  data,
  coloring,
  resolution,
  selectedUnit,
  onChangeSelectedUnit,
  size = 300,
  labels,
  padding = 0,
  showDots,
}) => {
  const state = useRef<PrivateState>({
    isPointerDown: false,
    unitOnPointerDown: undefined,
    isMoved: false,
    previouslySelectedUnit: undefined,
  }).current

  const colors =
    data !== undefined && coloring !== undefined
      ? data.map((v) => coloring(v))
      : new Array<undefined>(resolution * resolution).fill(undefined)
  const cellSize = size / resolution
  const unitR = Math.floor(cellSize * 0.1)
  const selectedUnitR = Math.floor(cellSize * 0.3)

  const dots = useMemo(() => {
    if (showDots === undefined) return []

    const seed = showDots.reduce((acc, n, i) => acc + n * (i + 1), 0)
    const rand = rng(seed)

    return showDots.map((n, i) => {
      const xi = i % resolution
      const yi = Math.floor(i / resolution)
      const x = (xi * size) / resolution
      const y = (yi * size) / resolution
      const cellSize = size / resolution
      const points = []
      for (let j = 0; j < n; j++) {
        points.push({
          x: x + rand() * cellSize,
          y: y + rand() * cellSize,
        })
      }
      return points
    })
  }, [resolution, showDots, size])

  return (
    <>
      <svg
        viewBox={`${-padding} ${-padding} ${size + padding * 2} ${
          size + padding * 2
        }`}
        style={{ width: `${size + padding * 2}px` }}
      >
        {colors.map((color, i) => {
          const xi = i % resolution
          const yi = Math.floor(i / resolution)
          const x = (xi * size) / resolution
          const y = (yi * size) / resolution
          const label = labels?.[i]

          return (
            <Fragment key={i}>
              <rect
                x={x.toFixed(2)}
                y={y.toFixed(2)}
                width={cellSize.toFixed(2)}
                height={cellSize.toFixed(2)}
                fill={color ?? '#fff'}
                onPointerDown={() => {
                  state.isPointerDown = true
                  state.unitOnPointerDown = i
                  state.isMoved = false
                  state.previouslySelectedUnit = selectedUnit
                }}
                onPointerUp={() => {
                  state.isPointerDown = false
                  if (!state.isMoved && state.previouslySelectedUnit === i) {
                    onChangeSelectedUnit?.(undefined)
                  } else {
                    onChangeSelectedUnit?.(i)
                  }
                }}
                onPointerMove={() => {
                  if (state.isPointerDown) {
                    onChangeSelectedUnit?.(i)
                    if (state.unitOnPointerDown !== i) {
                      state.isMoved = true
                    }
                  }
                }}
              />
              {dots[i]?.map((dot, j) => (
                <circle
                  key={j}
                  cx={dot.x}
                  cy={dot.y}
                  r={1}
                  fill="#000"
                  opacity={0.3}
                />
              ))}
              {selectedUnit === i && (
                <circle
                  cx={x + cellSize / 2}
                  cy={y + cellSize / 2}
                  r={selectedUnitR}
                  fill="#fff"
                  stroke="#d00"
                  strokeWidth={2}
                  style={{ pointerEvents: 'none' }}
                  opacity={0.8}
                />
              )}
              {label && (
                <circle
                  cx={x + cellSize / 2}
                  cy={y + cellSize / 2}
                  r={unitR}
                  fill="#000"
                  style={{ pointerEvents: 'none' }}
                />
              )}
            </Fragment>
          )
        })}
        <rect
          x="0"
          y="0"
          width={size}
          height={size}
          fill="none"
          stroke="#000"
        />
        {labels?.map((labelElem, i) => {
          if (labelElem === undefined) return null

          const labels = Array.isArray(labelElem) ? labelElem : [labelElem]
          const xi = i % resolution
          const yi = Math.floor(i / resolution)
          const x = (xi * size) / resolution + cellSize / 2
          const y = (yi * size) / resolution + cellSize / 2 + 14

          return (
            <Fragment key={i}>
              {labels.map((label, j) => {
                const y_ = y + 14 * j
                return (
                  <g key={j} transform={`rotate(-10, ${x}, ${y_})`}>
                    <text
                      x={x}
                      y={y_}
                      dominantBaseline="middle"
                      textAnchor="middle"
                      fontSize={12}
                      style={{ pointerEvents: 'none', userSelect: 'none' }}
                      fill="#555"
                      paintOrder="stroke"
                      stroke="#fffc"
                      strokeWidth={3}
                    >
                      {label}
                    </text>
                  </g>
                )
              })}
            </Fragment>
          )
        })}
      </svg>
    </>
  )
}
