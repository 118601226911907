import { createArray } from '@odiak/numjs'
import axios from 'axios'
import { RawResults, Results } from './types/result'
import { StateSetter } from '../../common/types/alias'

export const fetchLearningResult = async (setProgress: StateSetter<number>) => {
  const res = await axios.get<RawResults>('/data/results_2023-12-31.json', {
    onDownloadProgress(progressEvent) {
      if (progressEvent.total == null) {
        setProgress(100)
        return
      }
      const progress = (progressEvent.loaded * 100) / progressEvent.total
      setProgress(Math.round(progress))
    },
  })
  return convertToLearningResult(res.data)
}

const convertToLearningResult = (rawResults: RawResults): Results => {
  return {
    model1: {
      ...rawResults.model1,
      y: createArray(rawResults.model1.y).map((v) => v / 4 + 0.5),
      z1: createArray(rawResults.model1.z1),
      z2: createArray(rawResults.model1.z2),
      zeta1: createArray(rawResults.model1.zeta1),
      zeta2: createArray(rawResults.model1.zeta2),
    },
    model2: {
      ...rawResults.model2,
      y: createArray(rawResults.model2.y),
      z1: createArray(rawResults.model2.z1),
      z2: createArray(rawResults.model2.z2),
      z3: createArray(rawResults.model2.z3),
      zeta1: createArray(rawResults.model2.zeta1),
      zeta2: createArray(rawResults.model2.zeta2),
      zeta3: createArray(rawResults.model2.zeta3),
    },
    model3: {
      ...rawResults.model3,
      y: createArray(rawResults.model3.y),
      z1: createArray(rawResults.model3.z1),
      z2: createArray(rawResults.model3.z2),
      zeta1: createArray(rawResults.model3.zeta1),
      zeta2: createArray(rawResults.model3.zeta2),
    },
  }
}
