import { useCallback, useState } from 'react'

function getInitialValue<T>(initialState: T | (() => T)) {
  return typeof initialState === 'function'
    ? (initialState as () => T)()
    : initialState
}

export function usePersistedState<T>(key: string, initialState: T | (() => T)) {
  const [state, setState] = useState<T>(() => {
    const stored = localStorage.getItem(key)
    if (stored === null) return getInitialValue(initialState)

    try {
      return JSON.parse(stored)
    } catch (e) {
      console.error(e)
      return getInitialValue(initialState)
    }
  })

  const setStateWrapper = useCallback(
    (update: T | ((prev: T) => T)) => {
      setState((prev) => {
        const newState =
          typeof update === 'function'
            ? (update as (prev: T) => T)(prev)
            : update
        try {
          localStorage.setItem(key, JSON.stringify(newState))
        } catch (e) {
          console.error(e)
        }
        return newState
      })
    },
    [key],
  )

  return [state, setStateWrapper] as const
}
