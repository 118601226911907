import axios from 'axios'
import {
  Label,
  Labels,
  ItemLabel,
  ImpressionLabel,
  UserLabel,
} from './types/labels'
import { createArray } from '@odiak/numjs'
import { StateSetter } from '../../common/types/alias'

type Cache = Record<string, Labels>

const CACHE: Cache = {}

type RawLabels = Omit<Labels, 'user_attr_nd'>

export const fetchLabels = async (
  setProgress: StateSetter<number>,
): Promise<Labels> => {
  if (CACHE['input']) {
    return Promise.resolve(CACHE['input'])
  }
  const original = (
    await axios.get<RawLabels>('/data/input_2023-12-31.json', {
      onDownloadProgress(progressEvent) {
        if (progressEvent.total == null) {
          setProgress(100)
          return
        }
        const progress = (progressEvent.loaded * 100) / progressEvent.total
        setProgress(Math.round(progress))
      },
    })
  ).data

  return {
    ...original,
    user_attr_nd: createArray(original.user_attr),
    item_label: original.item_label.map((item) => {
      return {
        ...item,
        // データのtypoを修正する（typoが無くても動くようにする）
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        brand_name: (item as any).brand_nane ?? item.brand_name,
      }
    }),
  }
}

export const isItemLabel = (
  label: Label[number],
): label is ItemLabel[number] => {
  return Object.prototype.hasOwnProperty.call(label, 'img_path')
}

export const isImpressionLabel = (
  label: Label[number],
): label is ImpressionLabel[number] => {
  return (
    Object.prototype.hasOwnProperty.call(label, 'question') &&
    !Object.prototype.hasOwnProperty.call(label, 'section_name')
  )
}

export const isUserLabel = (
  label: Label[number],
): label is UserLabel[number] => {
  return Object.prototype.hasOwnProperty.call(label, 'gender')
}

export const isLifestyleLabel = (
  label: Label[number],
): label is ImpressionLabel[number] => {
  return (
    Object.prototype.hasOwnProperty.call(label, 'question') &&
    Object.prototype.hasOwnProperty.call(label, 'section_name')
  )
}
