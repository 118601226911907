const a = 1664525
const c = 1013904223
const m = 4294967296

export function rng(seed: number): () => number {
  return () => {
    seed = (seed * a + c) % m
    return seed / m
  }
}
