import React, { FC } from 'react'
import { Visualization } from './Visualization'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html, body {
    font-family: sans-serif;
    background: #f8f8f8;
  }
`

export const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <Visualization />
    </>
  )
}
