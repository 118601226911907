import React, { FC, useMemo } from 'react'
import { useLabels, useResults } from '../functions/resources'
import { styled } from 'styled-components'
import { V2 } from './V2'
import { Loading } from '../Loading'

const Title = styled.h1`
  text-align: center;
  margin: 0;
  font-size: 26px;
`

const Copyright = styled.p`
  margin: 20px 0;
  font-size: 14px;
  color: #666;
  text-align: center;
`

export const Visualization: FC = () => {
  const { results, progress: resultProgress } = useResults()
  const { labels, progress: labelProgress } = useLabels()
  const loadingProgress = useMemo(
    () => Math.min(resultProgress, labelProgress),
    [resultProgress, labelProgress],
  )

  if (!results || !labels) {
    return <Loading progress={loadingProgress} />
  }

  return (
    <>
      <Title>Tensor Cubeによる有田焼印象評価データ可視化</Title>
      <V2 results={results} labels={labels} />
      <Copyright>
        This application is provided by Furukawa Laboratory, Kyushu Institute of
        Technology. Contact address: furukawa@brain.kyutech.ac.jp
      </Copyright>
    </>
  )
}
