import React, { FC } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  line-height: 1.6;
  margin-top: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LeadText = styled.div`
  font-size: 22px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
`

const Progressbar = styled.div`
  position: relative;
  max-width: 500px;
  width: 100%;
  margin: 30px auto 0;
  height: 30px;
  background: white;
  overflow: hidden;
  border: solid #274545;
`

const Progress = styled.span<{ progress: number }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${(props) => props.progress}%;
  background: #326666;
  transition: width 0.3s;
`

type Props = {
  progress: number
}

export const Loading: FC<Props> = (props) => {
  return (
    <Container>
      <LeadText>データを取得中... {props.progress}%</LeadText>
      <Progressbar>
        <Progress progress={props.progress}></Progress>
      </Progressbar>
    </Container>
  )
}
